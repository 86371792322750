/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { LocalizedLink, Wrapper, SliceZone, Title, SEO, Headline } from '../components'
import { PostListing, DescriptionCoolLink, DescriptionPair, Categories } from '../components/Listing/'
import { LocaleContext } from '../components/Layout'
import website from '../../config/website'

import {DateRange as DateIcon, PersonOutline as AuthorIcon} from 'emotion-icons/material'

const HeadlineWrapper = styled(Headline)`
  font-size: 1.25rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-flow: column;
  }
`

const PostWrapper = Wrapper.withComponent('main')
const PostArticle = styled.article``
const PostHeader = styled.header`
  padding-top: 6rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 4rem;
  }
`
const PostAside = styled.aside``

const Post = ({ data: { prismicPost, posts }, location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const { data } = prismicPost
  let categories
  if (data.categories[0].category) {
    categories = data.categories.map(c => c.category.document[0])
  }

  return (
    <>
      <SEO
        title={`${data.title.text} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={data.description}
        node={prismicPost}
        pageType={i18n.pages.blog.name}
        isArticle
      />
      <PostWrapper id={website.skipNavId} role="main">
        <PostArticle>
          <PostHeader>
            <h1>{data.title.text}</h1>
            <HeadlineWrapper>
              <DescriptionPair icon={<DateIcon/>} content={data.date}/>
              <DescriptionPair icon={<AuthorIcon/>} content={
                      <DescriptionCoolLink>
                        <LocalizedLink to={`/`}>Pedro Almeida</LocalizedLink>
                      </DescriptionCoolLink>
                    }/>
              <Categories categories={categories}/>
            </HeadlineWrapper>
          </PostHeader>
          <SliceZone allSlices={data.body} />
        </PostArticle>
        <PostAside>
            <Title style={{ marginTop: '4rem' }}>{i18n.pages.blog.other}</Title>
            <PostListing posts={posts.edges} />
        </PostAside>
      </PostWrapper>
    </>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              uid
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPostBodyCodeBlock {
            slice_type
            id
            primary {
              code_block {
                html
              }
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale }, uid: {ne: $uid }}) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
